import React, { useContext, useRef, useEffect, useState } from 'react'
import LogoText from '../LogoTetx/LogoText'
import { loadingContext } from '../../App'
import './HeadBlock.css'
import ButtonWidget from '../widget/buttonWidget'
import './loading.css'

const HeadBlock = () => {
  const setLoading = useContext(loadingContext)
  const [logoSize, setLogoSize] = useState(100)
  const HeadBlockRef = useRef(null)
  const loadingRef = useRef(null)
  const HeadBlockLeft = useRef(null)
  const loadingDiv = useRef(null)
  const loadingTextRef = useRef(null)

  useEffect(() => {
    if (setLoading['loading']) {
      setTimeout(() => {
        loadingTextRef.current.classList.remove('loadingTextLoad1')
      }, 1300 );
      setTimeout(() => {
        loadingTextRef.current.classList.remove('loadingTextLoad2')
        loadingRef.current.classList.remove('logoTextDivLoading2')
      }, 1800 );
      setTimeout(() => {
        loadingTextRef.current.classList.add('loadingTextActive')
        loadingRef.current.classList.remove('logoTextDivLoading2')
      }, 2100);
      setTimeout(() => {
        HeadBlockRef.current.classList.remove('HeadBlockLoad')
        loadingRef.current.classList.remove('logoTextDivLoading1')
        
        setLogoSize(165)
      }, 2800);
      setTimeout(() => {
        loadingDiv.current.style.opacity = '0'
      }, 3200);
      setTimeout(() => {
        loadingDiv.current.style.display = 'none'
        HeadBlockLeft.current.classList.remove('HeadBlockLeftScaleOFF')
      }, 3900);
    }
  }, [setLoading])
  return (
    <>
    <ButtonWidget/>
      <div ref={loadingDiv} className="loadingDiv">
        <div ref={loadingTextRef} className='loadingText loadingTextLoad1 loadingTextLoad2'>
          
        </div>
      </div>
      <div ref={HeadBlockRef} className='HeadBlockDiv HeadBlockLoad'>
        <div ref={HeadBlockLeft} className='HeadBlockLeft HeadBlockLeftScaleOFF'>
          <p>Мы создаём будущее</p>
          <LogoText refValue={loadingRef} size={logoSize} loading={true} />
          <h3>Самые технологичные и эффективные проекты</h3>
        </div>
        <div className='HeadBlockRigth'>
          <img className='locked' src="/static/img/laptop200.gif" alt="" />
        </div>
      </div>
    </>
  )
}

export default HeadBlock
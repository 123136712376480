import React, { useRef } from 'react'
import Web from './web'
import App from './app'
import Addiitional from './аdditional'
import Promotion from './promotion'
import { useState } from 'react'
import MultiPage from './multiPage'
import Shop from './shop'
import Cabinet from './cabinet'
import Custom from './custom'
import Crm from './crm'
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import './ourServices.css'
import './ourStyle.css'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/navigation";

const OurServices = () => {
    const slider = useRef()
    const ourServicesButton1 = useRef(null)
    const ourServicesButton2 = useRef(null)
    const ourServicesButton3 = useRef(null)
    const ourServicesButton4 = useRef(null)
    const ourServicesButton5 = useRef(null)
    const [ourStyleWidth, setOurStyleWidth] = useState(null)


    const [menuPosition, setMenuPosition] = useState(1)
    const [swiper, setSwiper] = useState(null)

    function menuSlider(position) {
        setMenuPosition(position)
        ourServicesButton1.current.classList.remove('ourServicesButtonActive')
        ourServicesButton2.current.classList.remove('ourServicesButtonActive')
        ourServicesButton3.current.classList.remove('ourServicesButtonActive')
        ourServicesButton4.current.classList.remove('ourServicesButtonActive')
        ourServicesButton5.current.classList.remove('ourServicesButtonActive')
        slider.current.classList.remove('ourServicesMenuSliderPosition1')
        slider.current.classList.remove('ourServicesMenuSliderPosition2')
        slider.current.classList.remove('ourServicesMenuSliderPosition3')
        slider.current.classList.remove('ourServicesMenuSliderPosition4')
        slider.current.classList.remove('ourServicesMenuSliderPosition5')

        if (position === 1) {
            slider.current.classList.add('ourServicesMenuSliderPosition1')
            ourServicesButton1.current.classList.add('ourServicesButtonActive')
            swiper.slideTo(0)

        }
        else if (position === 2) {
            slider.current.classList.add('ourServicesMenuSliderPosition2')
            ourServicesButton2.current.classList.add('ourServicesButtonActive')
            swiper.slideTo(1)

        }
        else if (position === 3) {
            slider.current.classList.add('ourServicesMenuSliderPosition3')
            ourServicesButton3.current.classList.add('ourServicesButtonActive')
            swiper.slideTo(2)

        }
        else if (position === 4) {
            slider.current.classList.add('ourServicesMenuSliderPosition4')
            ourServicesButton4.current.classList.add('ourServicesButtonActive')
            swiper.slideTo(3)

        }
        else {
            slider.current.classList.add('ourServicesMenuSliderPosition5')
            ourServicesButton5.current.classList.add('ourServicesButtonActive') 
            swiper.slideTo(4)

        }
    }

    const hideSlide = (item) => {
        let idMultiPageH = document.getElementById('idMultiPage').offsetHeight
        let idShopH = document.getElementById('idShop').offsetHeight
        let idCabinetH = document.getElementById('idCabinet').offsetHeight
        let idCrmH = document.getElementById('idCrm').offsetHeight
        let idCustomH = document.getElementById('idCustom').offsetHeight
        if (item === 0) {
            setOurStyleWidth(idMultiPageH)
        }
        if (item === 1) {
            setOurStyleWidth(idShopH)
        }
        if (item === 2) {
            setOurStyleWidth(idCabinetH)
        }
        if (item === 3) {
            setOurStyleWidth(idCrmH)
        }
        if (item === 4) {
            setOurStyleWidth(idCustomH)
        }
    }

    const chancheSwiper = () => {
        if (swiper) {
            menuSlider(swiper.realIndex + 1)
            hideSlide(swiper.realIndex)
        }
    }

    return (
        <div className='ourServicesDiv' id='ourServices'>
            <p className="siteHeader">Наши услуги</p>
            <div className='ourServicesMenuDiv'>
                <div ref={slider} className='ourServicesMenuSlider'></div>
                <div className='ourServicesMenu'>
                    <div ref={ourServicesButton1} onClick={() => menuSlider(1)} className='ourServicesButton'>
                        <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.10435 1.58329C0.71633 1.97363 0.498535 2.50166 0.498535 3.05204C0.498535 3.60243 0.71633 4.13046 1.10435 4.52079L8.47935 12L1.10435 19.375C0.71633 19.7653 0.498535 20.2933 0.498535 20.8437C0.498535 21.3941 0.71633 21.9221 1.10435 22.3125C1.29803 22.5077 1.52844 22.6627 1.78232 22.7685C2.03619 22.8743 2.3085 22.9287 2.58352 22.9287C2.85854 22.9287 3.13085 22.8743 3.38472 22.7685C3.6386 22.6627 3.86901 22.5077 4.06269 22.3125L12.896 13.4791C13.0913 13.2855 13.2463 13.055 13.352 12.8012C13.4578 12.5473 13.5123 12.275 13.5123 12C13.5123 11.7249 13.4578 11.4526 13.352 11.1988C13.2463 10.9449 13.0913 10.7145 12.896 10.5208L4.06269 1.58329C3.86901 1.38803 3.6386 1.23304 3.38472 1.12727C3.13085 1.0215 2.85854 0.967045 2.58352 0.967045C2.3085 0.967045 2.03619 1.0215 1.78232 1.12727C1.52844 1.23304 1.29803 1.38803 1.10435 1.58329Z" fill="#CFF80A" />
                        </svg>
                        <p>Многостраничный</p>
                    </div>
                    <div ref={ourServicesButton2} onClick={() => menuSlider(2)} className='ourServicesButton'>
                        <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.10435 1.58329C0.71633 1.97363 0.498535 2.50166 0.498535 3.05204C0.498535 3.60243 0.71633 4.13046 1.10435 4.52079L8.47935 12L1.10435 19.375C0.71633 19.7653 0.498535 20.2933 0.498535 20.8437C0.498535 21.3941 0.71633 21.9221 1.10435 22.3125C1.29803 22.5077 1.52844 22.6627 1.78232 22.7685C2.03619 22.8743 2.3085 22.9287 2.58352 22.9287C2.85854 22.9287 3.13085 22.8743 3.38472 22.7685C3.6386 22.6627 3.86901 22.5077 4.06269 22.3125L12.896 13.4791C13.0913 13.2855 13.2463 13.055 13.352 12.8012C13.4578 12.5473 13.5123 12.275 13.5123 12C13.5123 11.7249 13.4578 11.4526 13.352 11.1988C13.2463 10.9449 13.0913 10.7145 12.896 10.5208L4.06269 1.58329C3.86901 1.38803 3.6386 1.23304 3.38472 1.12727C3.13085 1.0215 2.85854 0.967045 2.58352 0.967045C2.3085 0.967045 2.03619 1.0215 1.78232 1.12727C1.52844 1.23304 1.29803 1.38803 1.10435 1.58329Z" fill="#CFF80A" />
                        </svg>
                        <p>Интернет-магазин</p>
                    </div>
                    <div ref={ourServicesButton3} onClick={() => menuSlider(3)} className='ourServicesButton'>
                        <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.10435 1.58329C0.71633 1.97363 0.498535 2.50166 0.498535 3.05204C0.498535 3.60243 0.71633 4.13046 1.10435 4.52079L8.47935 12L1.10435 19.375C0.71633 19.7653 0.498535 20.2933 0.498535 20.8437C0.498535 21.3941 0.71633 21.9221 1.10435 22.3125C1.29803 22.5077 1.52844 22.6627 1.78232 22.7685C2.03619 22.8743 2.3085 22.9287 2.58352 22.9287C2.85854 22.9287 3.13085 22.8743 3.38472 22.7685C3.6386 22.6627 3.86901 22.5077 4.06269 22.3125L12.896 13.4791C13.0913 13.2855 13.2463 13.055 13.352 12.8012C13.4578 12.5473 13.5123 12.275 13.5123 12C13.5123 11.7249 13.4578 11.4526 13.352 11.1988C13.2463 10.9449 13.0913 10.7145 12.896 10.5208L4.06269 1.58329C3.86901 1.38803 3.6386 1.23304 3.38472 1.12727C3.13085 1.0215 2.85854 0.967045 2.58352 0.967045C2.3085 0.967045 2.03619 1.0215 1.78232 1.12727C1.52844 1.23304 1.29803 1.38803 1.10435 1.58329Z" fill="#CFF80A" />
                        </svg>
                        <p>Личный кабинет </p>
                    </div>
                    <div ref={ourServicesButton4} onClick={() => menuSlider(4)} className='ourServicesButton'>
                        <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.10435 1.58329C0.71633 1.97363 0.498535 2.50166 0.498535 3.05204C0.498535 3.60243 0.71633 4.13046 1.10435 4.52079L8.47935 12L1.10435 19.375C0.71633 19.7653 0.498535 20.2933 0.498535 20.8437C0.498535 21.3941 0.71633 21.9221 1.10435 22.3125C1.29803 22.5077 1.52844 22.6627 1.78232 22.7685C2.03619 22.8743 2.3085 22.9287 2.58352 22.9287C2.85854 22.9287 3.13085 22.8743 3.38472 22.7685C3.6386 22.6627 3.86901 22.5077 4.06269 22.3125L12.896 13.4791C13.0913 13.2855 13.2463 13.055 13.352 12.8012C13.4578 12.5473 13.5123 12.275 13.5123 12C13.5123 11.7249 13.4578 11.4526 13.352 11.1988C13.2463 10.9449 13.0913 10.7145 12.896 10.5208L4.06269 1.58329C3.86901 1.38803 3.6386 1.23304 3.38472 1.12727C3.13085 1.0215 2.85854 0.967045 2.58352 0.967045C2.3085 0.967045 2.03619 1.0215 1.78232 1.12727C1.52844 1.23304 1.29803 1.38803 1.10435 1.58329Z" fill="#CFF80A" />
                        </svg>
                        <p>CRM</p>
                    </div>
                    <div ref={ourServicesButton5} onClick={() => menuSlider(5)} className='ourServicesButton'>
                        <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.10435 1.58329C0.71633 1.97363 0.498535 2.50166 0.498535 3.05204C0.498535 3.60243 0.71633 4.13046 1.10435 4.52079L8.47935 12L1.10435 19.375C0.71633 19.7653 0.498535 20.2933 0.498535 20.8437C0.498535 21.3941 0.71633 21.9221 1.10435 22.3125C1.29803 22.5077 1.52844 22.6627 1.78232 22.7685C2.03619 22.8743 2.3085 22.9287 2.58352 22.9287C2.85854 22.9287 3.13085 22.8743 3.38472 22.7685C3.6386 22.6627 3.86901 22.5077 4.06269 22.3125L12.896 13.4791C13.0913 13.2855 13.2463 13.055 13.352 12.8012C13.4578 12.5473 13.5123 12.275 13.5123 12C13.5123 11.7249 13.4578 11.4526 13.352 11.1988C13.2463 10.9449 13.0913 10.7145 12.896 10.5208L4.06269 1.58329C3.86901 1.38803 3.6386 1.23304 3.38472 1.12727C3.13085 1.0215 2.85854 0.967045 2.58352 0.967045C2.3085 0.967045 2.03619 1.0215 1.78232 1.12727C1.52844 1.23304 1.29803 1.38803 1.10435 1.58329Z" fill="#CFF80A" />
                        </svg>
                        <p>Custom</p>
                    </div>
                </div>
            </div>

            <div className="ourServices" style={{height:ourStyleWidth}}>
                <Swiper
                    modules={[Autoplay]}
                    rewind={true}
                    slidesPerView={'1'}
                    centeredSlides={true}
                    spaceBetween={30}
                    onSlideChange={chancheSwiper}
                    onSwiper={setSwiper}

                    autoplay={true}
                    navigation={false}
                    pagination={{
                        clickable: true,
                    }}
                    className="socialSwiper"
                >
                    <SwiperSlide id='idMultiPage'>
                        <MultiPage  title='Многостраничный сайт' />
                    </SwiperSlide>
                    <SwiperSlide id='idShop'>
                        <Shop  title='Интернет-магазин' />
                    </SwiperSlide>
                    <SwiperSlide id='idCabinet'>
                        <Cabinet  title='Личный кабинет' />
                    </SwiperSlide>
                    <SwiperSlide id='idCrm'>
                        <Crm  title='CRM' />
                    </SwiperSlide>
                    <SwiperSlide id='idCustom'>
                        <Custom  title='Custom' />
                    </SwiperSlide>

                </Swiper>

                {/* {menuPosition === 1 ?
                    <MultiPage title='Многостраничный сайт' />
                    :
                    menuPosition === 2 ?
                        <Shop title='Интернет-магазин' />
                        :
                        menuPosition === 3 ?
                            <Cabinet title='Личный кабинет' />
                            :
                            menuPosition === 4 ?
                                <Crm title='CRM' />
                                :
                                <Custom title='Custom' />
                                
                } */}
                {/* {menuPosition === 1 ?
                    <Web />
                    :
                    menuPosition === 2 ?
                        <App />
                        :
                        menuPosition === 3 ?
                            <Addiitional />
                            :
                            <Promotion />
                } */}

            </div>

        </div>
    )
}

export default OurServices
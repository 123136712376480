import React, { useEffect, useRef, useState } from 'react'

const TopWork = (args) => {
    const topWorkRef = useRef(null)
    const [scrollHeiht, setScrollHeiht] = useState(0)

    const setScroll = () => {
        if (args.scroll) {
            // console.log(topWorkRef.current.getBoundingClientRect())
            if (window.innerWidth > 700) {
                setScrollHeiht(-1 * window.scrollY + topWorkRef.current.offsetTop - window.innerHeight + 200)
                topWorkRef.current.style.transition = '0.2s'
                if (scrollHeiht < -200) {
                    topWorkRef.current.style.backgroundPositionY = scrollHeiht + 203 + 'px'

                }
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', setScroll)
        return () => {
            window.removeEventListener("scroll", setScroll)
        }
    }, [setScroll])

    useEffect(() => {
        if (args.animate) {
            topWorkRef.current.style.backgroundPositionY = '100%'
            setTimeout(() => {
                topWorkRef.current.style.backgroundPositionY = '0%'
            }, 10000);
            setInterval(() => {
                topWorkRef.current.style.backgroundPositionY = '100%'
                setTimeout(() => {
                    topWorkRef.current.style.backgroundPositionY = '0%'
                }, 10000);
            }, 20000);
        }
    }, [args.animate])

    return (
        <div ref={topWorkRef} style={{ backgroundImage: 'url(' + args.image + ')' }} className='topWork'>
            <div className='topWorkTop'>
                <div className='topWorkCover'>
                    <p>{args.num}</p>
                </div>
            </div>
            <div className='topWorkInfo'>
                <div>
                    <p>{args.header}</p>
                    <span>{args.text}</span>
                </div>
                {args.link ?
                    <a rel="noopener noreferrer" target={'_blank'} href={args.link} >Подробнее</a>
                    :
                    <div></div>
                }
            </div>
        </div>
    )
}

export default TopWork
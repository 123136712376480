import React from 'react'
import Benefice from '../../elements/benefice/benefice'
import Bonus from '../../elements/bonus/bonus'
import HeadBlock from '../../elements/headBlock/HeadBlock'
import OurServices from '../../elements/ourServices/ourServices'
import Support from '../../elements/support/support'
import SupportBlock from '../../elements/supportBlock/suppoerBlock'
import Team from '../../elements/team/team'
import Tilda from '../../elements/tildaBlock/tilda'
import TopPortfolio from '../../elements/topPortfolio/topPortfolio'
import Credit from '../../elements/credit/credit'
import './home.css'

const Home = () => {
  return (
    <div className='homeDiv'>
        <HeadBlock/>
        <Benefice/>
        <TopPortfolio/>
        <Bonus/>        
        {/* <Tilda/> */}
        <OurServices/>
        <Credit/>
        <Team/>
        <SupportBlock/>
        <Support/>
    </div>
  )
}

export default Home
import React from 'react'

const MultiPage = (args) => {
    return (
        <>
            <h2 className="ourHeader">{args.title}</h2>
            <div className='serviceSection'>
                <div className="serviceSmall serviceSmallAnimated">
                    <div className='serviceDivAnimation'>
                        <div></div>
                    </div>
                    <h1>Базовый <br /> уровень:</h1>
                    <h2>от 95 000 т.р<br /><h6>Срок 7-14 д.</h6></h2>
                    <p>Проект состоит из </p>
                    <ul>
                        <li>Главная страница: базовые цвета, минималистичный дизайн, обычные шрифты. Основные данные о компании. Меню навигации.</li>
                        <li>Страницы услуг или товаров (галерея с подробным описанием).</li>
                        <li>Контактная информация: данные о компании, карта местоположения.</li>
                        <li>О нас: информация о компании, фотографии сотрудников.</li>
                        <li>Блог или новостная страница (по желанию): новости, статьи, блог.</li>
                        <li>Footer (нижняя часть сайта): данные о компании, ссылки на соц. сети, разделы сайта. </li>
                        <li>Адаптивность (кроссплатформенность): универсальный дизайн для всех гаджетов.</li>
                    </ul>
                </div>
                <div className="serviceWide">
                    <div className='serviceWideBlock'>
                        <h1>Средний <br /> уровень:</h1>
                        <h2>от 190 000 т.р<br /><h6>Срок 14-21 д.</h6></h2>
                        <p>Проект состоит из </p>
                        <ul>
                            <li>Главная страница: уникальный дизайн (3D-анимация). Персонализированный контент и рекомендации для пользователей. </li>
                            <li>Страницы услуг или товаров: дополнительные функции (фильтры, сравнение товаров, расширенные параметры поиска, онлайн-заказ с интеграцией оплаты).</li>
                            <li>Контактная информация: интерактивные карты с возможностью поиска и маршрутов, онлайн-чат.</li>
                            <li>Система управления контентом (CMS) (легкость редактирования контента).</li>
                            <li>Интеграция с внешними системами (API).</li>
                            <li>Многоязычность сайта (до 3х языков) </li>
                            <li>Базовая административная панель.</li>
                            <li>SEO-оптимизация: улучшенная.Поднятие сайта в поисковых системах. </li>
                            <li>Базовая настройка Яндекс директ (1 компания)</li>
                        </ul>
                    </div>
                    <div className='serviceWideBlock'>
                        <h1>Продвинутый <br /> уровень:</h1>
                        <h2>от 210 000 т.р<br /><h6>Срок более 21 д.</h6></h2>
                        <p>Проект состоит из </p>
                        <ul>
                            <li>Главная страница: сложный дизайн (слайдеры), дополнительные разделы и блоки с контентом. </li>
                            <li>Страницы услуг или товаров: дополнительные элементы (отзывы, галерея, спец. предложения).</li>
                            <li>Новостная рассылка. </li>
                            <li>О нас: подробная информация о компании, ее ценностях и миссии. </li>
                            <li>Блог или новостная страница: разделение статей по категориям и тегам. Возможность редактирования блога и комментирование статей улучшает SEO продвижение. </li>
                            <li>SEO-оптимизация: Оптимизация для поисковых систем на базовом уровне. </li>
                            <li>Интерактивные формы заказа, регистрации и обратной связи.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MultiPage
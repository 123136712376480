import React from 'react'
import './layout.css'


const Header = () => {
  return (
    <>
      <div className="header">
        <a href='#ourServices'>Услуги</a>
        <a href='#portfolio'>Наши работы</a>
        <a href='#contact'>Контакты</a>
      </div>
    </>
  )
}

export default Header
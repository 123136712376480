import React, { useContext, useRef } from 'react'
import { loadingContext } from '../../App'
import './bonus.css'

const Bonus = () => {
    const setLoading = useContext(loadingContext)

    const backBonusRef = useRef()

    function backBonus() {
        backBonusRef.current.classList.toggle('backBonusActive')
    }
    return (
        <div className='bonusDiv'>
            <p className='siteHeader'>Бонус каждому <br /> клиенту</p>
            <div className='bonus'>
                <div className="bonusLeft">
                    <div>
                        <p>01</p>
                        <span>Техническое обслуживание проекта <br /> 60 дней вне очереди</span>
                    </div>
                    <div>
                        <p>02</p>
                        <span>Гарантия на проект 12 месяцев</span>
                    </div>
                    <div>
                        <p>03</p>
                        <span>Установка проекта на хостинг бесплатно</span>
                    </div>
                </div>
                <div className="bonusRigth">
                    <img onLoad={() => setLoading['setLoading'](true)} ref={backBonusRef} className='backBonus' src="/static/img/backBonus.png" alt="" />
                    <img onMouseEnter={backBonus} onMouseLeave={backBonus} className='bonus' src="/static/img/bonus.png" alt="" />
                </div>
            </div>

        </div>
    )
}

export default Bonus
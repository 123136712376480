import React from 'react'
import { Link } from "react-router-dom";
import LogoText from '../elements/LogoTetx/LogoText'

const Footer = () => {

    return (
        <>
            <div className='footerShadow'></div>
            <div className='footerDiv' id='contact'>
                <div className='footerTop'>
                    <div className='footerLogoPart'>
                        <span>Мы создаём будущее</span>
                        <LogoText size={70}/>
                    </div>
                    <section>
                        <span>содержание</span>
                        <a href='#ourServices'>Наши услуги</a>
                        <a href='#benefice'>Наши преимущества</a>
                        <a href='#portfolio'>Топ 3 наших работ</a>
                        <a href='#team'>Наши руководители</a>
                    </section>
                    <div className='footerInfoPart'>
                        <span>контакты</span>
                        <a>Телефон <a href="tel:89209569995">8 (920) 956-99-95</a> </a>
                        <a >Эл. почта: <a href='mailto:saiters.ru@mail.ru' >saiters.ru@mail.ru</a></a>
                        <a>
                            Часы работы: <br />
                            <a>пн.- пт. с 9:00 до 21:00 по мск. <br /></a>
                            <a>сб. - вс. с 11:00 до 21:00 по мск.</a>
                        </a>
                        <a className='footerInfoPartIcons'>
                            <a target={'_blank'} href='https://wa.me/79209569995'><img src="/static/img/whatsappIcon.png" alt="" /></a>
                            <a target={'_blank'} href='https://t.me/saiters_ru'><img src="/static/img/telegramIco.png" alt="" /></a>
                        </a>
                    </div>
                </div>
                <div className='footerBottom'>
                    <a >
                        ИП Исаев Николай Николаевич <br />
                        ИНН: 622907207610 <br />
                        ОГРНИП: 323623400016963 <br />
                        Все права защищены © 2020-{new Date().getFullYear()}.
                    </a>
                    <a target={'_blank'} href='/static/file/privacy_policy.pdf'>Политика конфиденциальности</a>
                </div>
            </div>
        </>
    )
}

export default Footer
import React from 'react'

const Crm = (args) => {
    return (
        <>
            <h2 className="ourHeader">{args.title}</h2>
            <div className='serviceSection serviceSectionCRM'>
                <div className="serviceWide">
                    <div className='serviceWideBlock'>
                        <h2>от 350 000 т.р<br /><h6>Срок от 30 д.</h6></h2>
                        <p>Структуризировать проект можно из: </p>
                        <ul>
                            <li>Дизайн: любой сложности.</li>                    
                            <li>Управление контактами: хранение информации, редактирование, истории взаимодействия.</li>                    
                            <li>Управление сделками: отслеживание, контроль стадий, закрытие. </li>                    
                            <li>Управление задачами и событиями: создание, назначение и контроль, напоминания. </li>                    
                            <li>Управление планами и задачами по продажам: планирование, постановка целей, контроль выполнения. </li>                    
                            <li>Управление интеграциями: с внешними сервисами и инструментами. </li>                    
                            <li>Отчетность и аналитика: сбор данных и их анализ. </li>                    
                            <li>Сегментация клиентов: группировка по разным параметрам. </li>                    
                            <li>Система массовых и персонализированных сообщений (SMS, push-уведомления, эл. письмо). </li>                    
                            <li>Управление инвентаризацией и складом. </li>                    
                            <li>Документооборот. </li>                    
                            <li>Управление проблемами и жалобами клиентов: регистрация, контроль, запросы в ТП. </li>                    
                            <li>Интеграция с системами учета: обмен данными с бухгалтерскими и финансовыми системами. </li>                    
                            <li>Мультиязычность и многовалютность. </li>                    
                            <li>Управление доступом: настройка прав доступа.</li>                    
                            <li>Многое другое</li>                    
                        </ul>
                    </div>

                </div>
                <h1 className="serviceSectionHeader">
                    Сложность зависит от дизайна, интеграции и дополнительных функций:
                </h1>
            </div>
        </>
    )
}

export default Crm
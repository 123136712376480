import { createContext, useState } from 'react';
import Header from './layout/header';
import Footer from './layout/footer';
import Home from './page/home/home';
import './css/style.css'
import { Route, Routes } from 'react-router';
import NotFound from './page/notFound/notFound';

export const loadingContext = createContext()

function App() {
  const [loading, setLoading] = useState(false)
  const [support, setSupport] = useState('')
  return (
      <loadingContext.Provider value={{ 'setLoading': setLoading, 'loading': loading, 'setSupport': setSupport,'support':support }}>
        <Routes>
          <Route path='/' element={
            <>
              <Header />
              <Home />
              <Footer />
            </>
          } />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </loadingContext.Provider>

  );
}

export default App;

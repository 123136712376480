import React from 'react'

const Shop = (args) => {
    return (
        <>
            <h2 className="ourHeader">{args.title}</h2>
            <div className='serviceSection'>
                <div className="serviceSmall">
                    <h1>Базовый <br /> уровень:</h1>
                    <h2>от 160 000 т.р<br /><h6>Срок от 20 д.</h6></h2>
                    <p>Проект состоит из </p>
                    <ul>
                        <li>Дизайн: базовые цвета, минималистичный дизайн, обычные шрифты.</li>
                        <li>Основные функции: просмотр и покупка товаров, фильтрация и сортировка, оформление заказа, система скидок и промокодов, уведомления по электронной почте о статусе заказа и акциях.</li>
                        <li>Адаптивность (кроссплатформенность): универсальный дизайн для всех гаджетов. </li>
                        <li>SEO-оптимизация: Оптимизация для поисковых систем на базовом уровне. </li>
                        <li>Интеграция с системами аналитики (базовый уровень).</li>                        
                    </ul>
                </div>
                <div className="serviceWide">
                    <div className='serviceWideBlock serviceSmallAnimated'>
                        <div className='serviceDivAnimation'>
                            <div></div>
                        </div>
                        <h1>Средний <br /> уровень:</h1>
                        <h2>от 240 000 т.р<br /><h6>Срок от 40 д.</h6></h2>
                        <p>Проект состоит из </p>
                        <ul>
                            <li>Дизайн: уникальный, креативный, 3D-анимация, интерактивные элементы. </li>
                            <li>Функции: дополнительные функции (персонализированные рекомендации, рассылки, кастомизированные фильтры и др.). </li>
                            <li>Интеграция с социальными сетями и аналитика (оптимизация производительности).. </li>
                            <li>Управление контентом: редактирование информации. Возможность делиться контентом.</li>
                            <li>Безопасность: высокий уровень безопасности и защиты данных клиентов.</li>
                            <li>Каталог продуктов с фильтрами и поиском.</li>
                            <li>Многоязычность сайта (до 3х языков) и многовалютность.</li>
                            <li>Форма онлайн-заказа с интеграцией оплаты.</li>
                            <li>Базовая настройка Яндекс директ (1 компания)</li>
                        </ul>
                    </div>
                    <div className='serviceWideBlock'>
                        <h1>Продвинутый <br /> уровень:</h1>
                        <h2>от 290 000 т.р<br /><h6>Срок от 30 д.</h6></h2>
                        <p>Проект состоит из </p>
                        <ul>
                            <li>Дизайн: сложный. Учитывает ваш бренд и целевую аудиторию. Расширенная цветовая палитра и шрифты.</li>
                            <li>Функции: расширенная система навигации с фильтрацией и сортировкой товаров, рейтинги и отзывы клиентов с добавлением фотографий, подсказки, связанные товары. </li>
                            <li>Увеличенная адаптация для мобильных устройств: Дизайн будет более адаптирован для мобильных устройств, чтобы обеспечить удобство на всех платформах</li>
                            <li>SEO-оптимизация: улучшенная.Поднятие сайта в поисковых системах. </li>
                            <li>Аналитический отчет о поведении пользователей на сайте.</li>
                            <li>Система уведомлений клиентов.</li>
                            <li>Интеграция с платежными системами (оплата банковскими картами).</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Shop
import React from 'react'
import './logoText.css'

const LogoText = (args) => {
  return (
    args.loading?
    <div style={{height:args.size}} ref={args.refValue} className='logoTextDiv logoTextDivLoading1 logoTextDivLoading2'>
        <p style={{fontSize:args.size,lineHeight:args.size+'px'}} className='LogoText'>SAITERS</p>
    </div>
    :
    <div style={{height:args.size}} className='logoTextDiv logoTextDivHidden'>
        <p style={{fontSize:args.size,lineHeight:args.size+'px'}} className='LogoText'>SAITERS</p>
    </div>
  )
}

export default LogoText
import React from 'react'
import './topPortfolio.css'
import TopWork from './topWork'

const TopPortfolio = () => {
  return (
    <div className='topPortfolioDiv' id='portfolio'>
        <p className='siteHeader'>Топ 3 наших работ</p>
        <div className="topPortfolio">
            <TopWork num='01' header="baksbo" scroll={true} animate={false} text='сервис массовых рассылок' image='/static/img/portfolio/baksbo.jpeg' link='https://baksbo.ru/'/>
            <TopWork num='02' header="pitierra" scroll={true} text='Система отчистки воды' image='/static/img/portfolio/pitierra.ru_.jpg' link='https://pitierra.ru/'/>
            <TopWork num='03' header="Mirror Life" scroll={true} text='Сервис мебельный изделий' image='/static/img/portfolio/site2.jpg'/>
        </div>
    </div>
  )
}

export default TopPortfolio
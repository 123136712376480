import React from 'react'

const Custom = (args) => {
    return (
        <>
            <h2 className="ourHeader">{args.title}</h2>
            <div className='serviceSection'>
                <h1 className="serviceSectionHeader">
                    “Сайты, которые рождаются из вашего вдохновения”
                </h1>
                <div className="serviceWide">
                    <div className='serviceWideBlock serviceSmallAnimated'>
                        <div className='serviceDivAnimation'>
                            <div></div>
                        </div>
                        <h2>от 350 000 т.р<br /><h6>Срок от 30 д.</h6></h2>
                        <h4>Кастомный веб-сайт/веб-приложение предоставляет клиенту полную свободу для воплощения своих идей и требований, что позволяет создать уникальный и функциональный веб-проект любой сложности и задач.</h4>                        
                    </div>

                </div>
            </div>
        </>
    )
}

export default Custom
import React, { useContext } from 'react'
import { loadingContext } from '../../App'
import './credit.css'

const Credit = () => {
    const supportContext = useContext(loadingContext)
    return (
        <div className='creditSection'>
            <p className='siteHeader'>Способы оплаты</p>
            <div className='creditDiv'>
                <div className='credit'>
                    <p>Оплата в два этапа</p>
                    <h2>60/40% <span></span></h2>
                    <h4>Сайт или мобильное приложение можно оплатить в два этапа, это позволяет снизить вашу финансовую нагрузку.
                    </h4>
                    <div className="hr"></div>
                    <button onClick={() => supportContext['setSupport']('Оплата в два этапа')}>Подробнее</button>
                </div>

                <div className='credit'>
                    <p>Рассрочка  </p>
                    <h2>0% <span>/ от 3х до 24х месяцев</span></h2>
                    <p>Без переплат</p>
                    <h4>Есть уникальная возможность приобрести
                        сайт  или мобильное приложение в
                        рассрочку от наших партнеров.
                    </h4>
                    <div className="hr"></div>
                    <img src="/static/img/tinkoff_logo.png" alt="" />
                    <img src="/static/img/mtsbank.png" alt="" />
                    <button onClick={() => supportContext['setSupport']('Рассрочка')}>Подробнее</button>
                </div>

                <div className='credit'>
                    <p>Аренда проекта</p>
                    <h2>от 12  <span>/ месяцев</span></h2>
                    <h4>Не оплачивайте сайт или мобильные приложение, возьмите его в аренду с момента сдачи проекта в работу.
                    </h4>
                    <div className="hr"></div>
                    <button onClick={() => supportContext['setSupport']('Аренда проекта')}>Подробнее</button>
                </div>

            </div>
        </div>
    )
}

export default Credit
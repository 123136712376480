import React from 'react'
import './team.css'

const Team = () => {
  return (
    <div className='teamDiv' id='team'>
        <p className='siteHeader'>Команда руководителей</p>
        <div className='team'>            
            <div className='teamPerson'>
                <img className='locked' src="/static/img/team/sasha.png" alt="" />
                <div>
                    <p>Александр Юрский</p>
                    <span>Marketing</span>
                </div>
            </div>
            <div className='teamPerson'>
                <img className='locked' src="/static/img/team/nikolay.png" alt="" />
                <div>
                    <p>Николай Исаев</p>
                    <span>Founder & CEO</span>
                </div>
            </div>
            <div className='teamPerson'>
                <img className='locked' src="/static/img/team/lilik.png" alt="" />
                <div>
                    <p>Lilik Grigoryan</p>
                    <span>UI/UX Designer</span>
                </div>
            </div>
            <div className='teamPerson'>
                <img className='locked' src="/static/img/team/vahe.png" alt="" />
                <div>
                    <p>Vahe Basmajyan</p>
                    <span>Software Developer</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Team
import React from 'react'

const Cabinet = (args) => {
    return (
        <>
            <h2 className="ourHeader">{args.title}</h2>
            <div className='serviceSection'>
                <h1 className="serviceSectionHeader">
                    Сложность зависит от дизайна, интеграции и дополнительных функций:
                </h1>
                <div className="serviceWide">
                    <div className='serviceWideBlock serviceSmallAnimated'>
                        <div className='serviceDivAnimation'>
                            <div></div>
                        </div>
                        <h2>от 230 000 т.р<br /><h6>Срок от 30 д.</h6></h2>
                        <p>Структуризировать проект можно из:</p>
                        <ul>
                            <li>Дизайн: любой сложности.</li>
                            <li>Профиль пользователя. Возможность изменения личных данных. </li>
                            <li>Настройки: предпочтения, уведомления, язык сайта, локации, безопасность (в т.ч. 2FA). </li>
                            <li>Заказы и покупки (для интернет-магазинов): история заказов просмотров, запросов. статус товаров и доставки, события в ЛК, отзывы, список избранный.</li>
                            <li>Управление подписками и платежами. </li>
                            <li>Уведомления и сообщения. Чат с поддержкой. </li>
                            <li>Аналитика и статистика (для аналитических сервисов).</li>
                            <li>Система рекомендаций (на основе предпочтений и истории покупок).</li>
                            <li>Интеграция с социальными сетями. </li>
                            <li>Дизайн: интерактивные элементы и анимации, бренд-дизайн. </li>
                            <li>Динамическая загрузка данных (без перезагрузки страницы).</li>
                            <li>Многое другое.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Cabinet
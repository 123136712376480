import React, { useRef, useState } from 'react'
import axios from 'axios';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './supportBlock.css'

const SupportBlock = () => {
  const success = useRef(null)
  const button = useRef(null)
  const nameRef = useRef(null)
  const numberRef = useRef(null)
  const checkRef = useRef(null)
  const checkInputRef = useRef(null)
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')

  function setError(type) {
    if (type === 'number') {
      numberRef.current.classList.add('errorInputNum')
      numberRef.current.focus()
      return
    }
    else if (type === 'name') {
      nameRef.current.classList.add('errorInput')
      nameRef.current.focus()
      return
    }
    else if (type === 'checkbox') {
      checkRef.current.classList.add('errorCheck')
      checkRef.current.focus()
      return
    }
  }

  function numberPlus() {
    if(!number){
      setNumber('+7')
    }
  }

  function successForm() {
    numberRef.current.classList.remove('errorInputNum')
    nameRef.current.classList.remove('errorInput')
    checkRef.current.classList.remove('errorCheck')

    if (name) {
      if (number && number.length > 10) {
        if (checkInputRef.current.checked) {
          button.current.disabled = true
          axios.post('/api/support', {
            name: name,
            number: number,
            description: 'нет'
          }).then(response => {
            if (response.status === 200) {
              button.current.disabled = false
              setName('')
              setNumber('')
              checkInputRef.current.checked = false
              
              success.current.style.display = 'flex'
              button.current.disabled = false
              setTimeout(() => {
                success.current.style.opacity = '1'
              }, 10);
              setTimeout(() => {
                success.current.style.opacity = '0'
              }, 3000);
              setTimeout(() => {
                success.current.style.display = 'none'
              }, 3300);
            }
          })
        } else {
          setError('checkbox')
        }
      } else {
        setError('number')
      }
    } else {
      setError('name')
    }

  }

  return (
    <div className='supportBlockDiv'>
      <div className='supportBlockText'><p>Получите консультацию по Вашему проекту. Обсудим цели, задачи и варианты решений</p></div>
      <div className='supportBlockformDiv '>
        <div className='supportBlockform'> 
          <div ref={success} className='successBlockForm'>
            <img src="/static/img/success.png" alt="" />
            <p>Заявка отправлена!</p>
            <p>С вами свяжется первый освободившийся менеджер в течение 30 минут.</p>
          </div>
          <p>Дайте нам знать о вашем проекте, и мы реализуем его в кратчайшие сроки.</p>

          <input ref={nameRef} value={name} onChange={event => setName(event.target.value)} type="text" placeholder='Имя' />
          <div ref={numberRef}>
          <PhoneInput onClick={numberPlus} placeholder="Телефон" value={number} onChange={setNumber} />
          </div>

          <div ref={checkRef} className='checkboxDiv'>
            <label className="checkbox style-c">
              <input ref={checkInputRef} type="checkbox" />
              <div className="checkbox__checkmark"></div>
              <div className="checkbox__body">Я согласен с <a target='_blank' href="/static/file/privacy_policy.pdf">политикой конфиденциальности</a></div>
            </label>
          </div>
          <button ref={button} onClick={successForm}>Отправить</button>
        </div>
        <div className='supportBlockLigth'></div>
      </div>
    </div>
  )
}

export default SupportBlock
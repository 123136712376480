import React from 'react'
import { Link } from 'react-router-dom'
import './notFound.css'

const NotFound = () => {
  return (
    <div className='notFoundDiv'>
        <p className='notFound'>404</p>
        <b>Страница не найдена</b>
        <span>Страница, которую вы ищете, похоже, не существует</span>
        <Link to={'/'}>перейти на главную страницу</Link>
    </div>
  )
}

export default NotFound
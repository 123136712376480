import React from 'react'
import { useRef } from 'react'
import './benefice.css'
import BeneficeText from './beneficeText'

const Benefice = () => {
    const beneficeLeftFon = useRef()
    function beneficeLeftFonFunc(bool) {
        if (bool) {
            beneficeLeftFon.current.classList.add('beneficeLeftFonActive')
            return
        }
        beneficeLeftFon.current.classList.remove('beneficeLeftFonActive')
    }

    return (
        <div className='beneficeDiv' id='benefice'>
            <p className='siteHeader'>наши преимущества</p>
            <div className="benefice">
                <div className='benefice_1'>
                    <div className='benefice_1_1'>
                        <div className='benefice_1_1_1'>
                            <h1>
                                <img src="/static/img/benefices/1.png" alt="" />
                                Технологичность
                            </h1>
                            <p>
                                используем самые современные подходы к разработке программных продуктов
                            </p>
                        </div>
                        <div className='benefice_1_1_2'>
                            <h1>
                                <img src="/static/img/benefices/5.png" alt="" />
                                Индивидуальный подход
                            </h1>
                            <p>
                                Каждый наш клиент - уникальная история, которую с гордостью пишем вместе с вами!
                            </p>
                        </div>
                    </div>
                    <div className='benefice_1_2'>
                        <div className='benefice_1_2_1'>
                            <h1>
                                <img src="/static/img/benefices/2.png" alt="" />
                                Опытная команда
                            </h1>
                            <p>
                                В нашей команде работают только лучшие специалисты в сфере разработки, которые имеют огромный опыт за своими плечами!
                            </p>
                        </div>
                        <div className='benefice_1_2_2'>
                            <h1>
                                <img src="/static/img/benefices/6.png" alt="" />
                                Контроль качества
                            </h1>
                            <p>
                                Все процессы контролируются руководителями команд и предоставляется полный отчет нашим клиентам по проделанным работам.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='benefice_2'>
                    <div className='benefice_2_1'>
                        <div className='benefice_2_1_1'>
                            <h1>
                                <img src="/static/img/benefices/3.png" alt="" />
                                ВКС
                            </h1>
                            <p>
                                Мы активно используем системы видеоконференцсвязи для вашего удобства и оперативности решения вопросов
                            </p>
                        </div>
                        <div className='benefice_2_1_2'>
                            <h1>
                                <img src="/static/img/benefices/4.png" alt="" />
                                Стоимость
                            </h1>
                            <p>
                                Одно из лучших предложений на рынке по соотношению “цена-качество”
                            </p>
                        </div>
                    </div>

                    <div className='benefice_2_2'>
                        <h1>
                            <img src="/static/img/benefices/7.png" alt="" />
                            Сложность проекта
                        </h1>
                        <p>Мы готовы реализовать любые ваши мысли и пожелания без каких-либо ограничений.</p>
                    </div>
                </div>
            </div>
            <div className="benefice_3">
                <div className='benefice_3_1'>
                    <h1>
                        <img src="/static/img/benefices/8.png" alt="" />
                        Техническая поддержка 24/7
                    </h1>
                    <p>Мы готовы реализовать любые ваши мысли и пожелания без каких-либо ограничений.</p>
                </div>
                <div className='benefice_3_2'>
                    <h1>
                        <img src="/static/img/benefices/9.png" alt="" />
                        Техническая поддержка 24/7
                    </h1>
                    <p>Мы готовы реализовать любые ваши мысли и пожелания без каких-либо ограничений.</p>

                </div>
            </div>

        </div>

    )
}

export default Benefice





{/* <div className='beneficeDiv' id='benefice'>
            <p className='siteHeader'>наши преимущества</p>
            <div className="benefice">
                <div className="beneficeLeft">
                    <div ref={beneficeLeftFon} className='beneficeLeftFon'></div>
                    <div className='beneficeLeftPhoene '>
                        <img className='locked' onMouseEnter={() => beneficeLeftFonFunc(true)} onMouseLeave={() => beneficeLeftFonFunc(false)} src="/static/img/phone.png" alt="" />                        
                    </div>
                </div>
                <div className="beneficeRight">
                    <BeneficeText
                    header={'Стоимость'}
                    text={'Качественная работа по доступной цене - только для вас, только у нас.'}
                    />
                    <div className='beneficeLine beneficeLineMobile'></div>
                    <BeneficeText
                    header={'Техническая поддержка 24/7'}
                    text={'Наши специалисты на связи круглые сутки и готовы вас консультировать тогда, когда удобно именно вам.'}
                    />
                    <div className='beneficeLine beneficeLineMobile'></div>
                    <div className='beneficeLine'></div>
                    <div className='beneficeLine'></div>
                    <BeneficeText
                    header={'Видео конференции'}
                    text={'Mы ценим ваше время, поэтому предлагаем все современные технологии для решения любых вопросов. От начала проекта до его завершения мы будем с вами на видео связи, не упуская ни одной детали. Работаем на любой платформе.'}/>
                    <div className='beneficeLine beneficeLineMobile'></div>
                    <BeneficeText
                    header={'Индивидуальный подход'}
                    text={'Для нас каждый клиент - уникальная история, которую мы пишем вместе. Мы найдем точки соприкосновения с любыми вашими мыслями и желаниями.'}
                    />
                    <div className='beneficeLine beneficeLineMobile'></div>
                    <div className='beneficeLine'></div>
                    <div className='beneficeLine'></div>
                    <BeneficeText
                    header={'Сложность проектов'}
                    text={'Не проблема для нашей команды, мы специализируемся на любых проектах и находим выход из любой ситуации.'}
                    />
                    <div className='beneficeLine beneficeLineMobile'></div>
                    <BeneficeText
                    header={'Скорость реализации проекта'}
                    text={'Вы не успеете придумать, чем скрасить ожидание, ведь после обсуждения тех. задания, мы выполним работу в кратчайшие сроки.'}
                    />
                    <div className='beneficeLine beneficeLineMobile'></div>
                </div>
            </div>
        </div> */}
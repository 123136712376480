import React, { useState, useRef, useEffect, useContext } from 'react'
import { loadingContext } from '../../App'
import axios from 'axios';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './support.css'


const Support = () => {
    const supportContext = useContext(loadingContext)
    const supportDiv = useRef(null)
    const form = useRef(null)
    const success = useRef(null)
    const button = useRef(null)
    const nameRef = useRef(null)
    const numberRef = useRef(null)
    const checkRef = useRef(null)
    const checkInputRef = useRef(null)

    const [active, setActive] = useState(false)

    const [name, setName] = useState('')
    const [number, setNumber] = useState('')


    function support() {
        success.current.style.display = 'none'
        if (active) {
            supportDiv.current.style.display = 'none'
            form.current.style.display = 'none'
            setActive(false)
            supportContext['setSupport']('')
            return
        }
        supportDiv.current.style.display = 'flex'
        form.current.style.display = 'flex'
        setActive(true)

    }

    function numberPlus() {
      if(!number){
        setNumber('+7')
      }
    }

    function setError(type) {
        if (type === 'number') {
          numberRef.current.classList.add('errorInputNum')
          numberRef.current.focus()
          return
        }
        else if (type === 'name') {
          nameRef.current.classList.add('errorInput')
          nameRef.current.focus()
          return
        }
        else if (type === 'checkbox') {
          checkRef.current.classList.add('errorCheck')
          checkRef.current.focus()
          return
        }
      }
    

    function successForm() {
        numberRef.current.classList.remove('errorInputNum')
        nameRef.current.classList.remove('errorInput')
        checkRef.current.classList.remove('errorCheck')
    
        if (name) {        
          if (number && number.length > 10) {
            if (checkInputRef.current.checked) {
              button.current.disabled = true
              axios.post('/api/support', {
                name: name,
                number: number,
                description: supportContext['support'],
              }).then(response => {
                if (response.status === 200) {
                  button.current.disabled = false
                  setName('')
                  setNumber('')
                  checkInputRef.current.checked = false
                  
                  success.current.style.display = 'flex'
                  button.current.disabled = false
                  setTimeout(() => {
                      success.current.style.opacity = '1'
                  }, 10);
                }
              })
            } else {
              setError('checkbox')
            }
          } else {
            setError('number')
          }
        } else {
          setError('name')
        }    
    }

    useEffect(() => {
        if (supportContext['support']) {
            support()
        }
    }, [supportContext['support']])

    return (
        <>
            {/* <div onClick={support} className='supportIco'></div> */}
            <div ref={supportDiv} onClick={support} className='supportDiv'>
                <div className='supportBlockLigth'></div>
            </div>
            <div ref={form} className='supportform'>
                <div ref={success} className='successForm'>
                    <img src="/static/img/success.png" alt="" />
                    <p>Заявка отправлена!</p>
                    <p>С вами свяжется первый освободившийся менеджер в течение 30 минут.</p>
                </div>
                {supportContext['support'] ?
                    <p>Оставить заявку на тему <br /> "{supportContext['support']}".</p>
                    :
                    <p>Дайте нам знать о вашем проекте, и мы реализуем его в кратчайшие сроки.</p>
                }
                
                <input ref={nameRef} value={name} onChange={event => setName(event.target.value)} type="text" placeholder='Имя' />
                
                <div ref={numberRef}>
                <PhoneInput onClick={numberPlus}  placeholder="Телефон" value={number} onChange={setNumber} />
                </div>
                <div ref={checkRef} className='checkboxDiv'>
                    <label className="checkbox style-c">
                        <input ref={checkInputRef} type="checkbox" />
                        <div className="checkbox__checkmark"></div>
                        <div className="checkbox__body">Я согласен с <a target='_blank' href="/static/file/privacy_policy.pdf">политикой конфиденциальности</a></div>
                    </label>
                </div>
                <button ref={button} onClick={successForm}>Отправить</button>
            </div>
        </>
    )
}

export default Support